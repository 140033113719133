
import {defineComponent} from "vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import ClaimLink from "@/views/claim/ClaimLink.vue";
import ClaimStatusMsg from "@/views/claim/ClaimStatusMsg.vue";

export default defineComponent({
  name: "ClaimViewCard",
  components: {ClaimStatusMsg, ClaimLink, Row},
  props: {
    view: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }
})
